import moment from 'moment';

import {
  MetricAggregationDataPointDto,
  MetricDefinitionDto,
  MetricDefinitionDtoDataPointType,
  MetricDefinitionDtoPeriod,
  MetricDefinitionDtoSummarize,
  MetricDefinitionDtoTrendType,
  MetricTargetDefinitionDto
} from '../api/generated';
import { isLastDayOfMonth } from './dateUtil';
import { orderBy } from 'lodash';

export enum TimePeriod {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUALLY = 'semiannually',
  ANNUALLY = 'annually'
}

// countMaxValue - object is necessary to maximize the display of the metric period dependence on the graph.
// For example countMaxValue[monthly] = 12 points
export const countMaxValue = {
  monthly: 12,
  quarterly: 4,
  semiannually: 2,
  annually: 1
};

// currentTime - object depending on the period in the metric we get the current month, quarter or semiannually in numerical terms
// For example, if current month is January - currentTime[monthly] = 1
export const currentTime = {
  monthly: new Date().getMonth() + 1,
  quarterly: Math.floor(new Date().getMonth() / 3 + 1),
  semiannually: Math.floor(new Date().getMonth() / 6 + 1)
};

// The label function returns a short version of the month, quarterly or semiannually, which will be displayed on the chart depending on the period in the metric and the number passed to it
// For example, label(period = monthly, number = 1) we will get Jan in return
export const label = (period, number) =>
  period === TimePeriod.MONTHLY
    ? moment()
      .month(number - 1)
      .format('MMM')
    : period === TimePeriod.QUARTERLY
      ? `Q${number}`
      : period === TimePeriod.SEMIANNUALLY
        ? `H${number}`
        : '';


export const metricTrendsNames = {
  [MetricDefinitionDtoTrendType.more]: 'Higher is better',
  [MetricDefinitionDtoTrendType.less]: 'Lower is better'
} as const;

export const periodTitles = {
  [MetricDefinitionDtoPeriod.monthly]: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  [MetricDefinitionDtoPeriod.annually]: ['Y']
};


const getShouldHandleDatapoint = (
  yearly: boolean,
  isLastYear: boolean,
  dataPointDate: Date,
  lastYear: number,
  currentYear: number,
  monthToStart: number
) => {
  if (yearly) {
    return isLastYear
      ? (dataPointDate.getFullYear() === lastYear &&
        dataPointDate.getMonth() + 1 >= monthToStart) ||
      (dataPointDate.getFullYear() === lastYear + 1 &&
        dataPointDate.getMonth() + 1 < monthToStart)
      : (dataPointDate.getFullYear() === currentYear &&
        dataPointDate.getMonth() + 1 >= monthToStart) ||
      (dataPointDate.getFullYear() === currentYear + 1 &&
        dataPointDate.getMonth() + 1 < monthToStart);
  }
  return true;
};


export const buildDataPointsForChart: <
  T extends MetricAggregationDataPointDto | MetricTargetDefinitionDto
>(
  monthToStart: number,
  metricDatapoints: T[],
  yearly: boolean,
  isLastYear?: boolean
) => Array<MetricAggregationDataPointDto | MetricTargetDefinitionDto> = (
  monthToStart,
  metricDatapoints,
  yearly,
  isLastYear
) => {
    if (metricDatapoints?.length) {
      const isDatapointsTargets = (metricDatapoints as MetricTargetDefinitionDto[]).some(
        datapoint => datapoint.timestamp !== undefined
      );
      const datapointDateAttribute = isDatapointsTargets ? 'timestamp' : 'date';


      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const lastYear = currentDate.getFullYear() - 1;


      const datapoints = metricDatapoints
        .filter(datapoint => {
          const dataPointDate = new Date(datapoint[datapointDateAttribute]);
          const shouldHandle = getShouldHandleDatapoint(
            yearly,
            isLastYear,
            dataPointDate,
            lastYear,
            currentYear,
            monthToStart
          );
          return shouldHandle;
        })
        .map(datapoint => {
          const dataPointDate = new Date(datapoint[datapointDateAttribute]);
          isLastYear && dataPointDate.setFullYear(dataPointDate.getFullYear() + 1);

          if (!isLastDayOfMonth(dataPointDate) && isDatapointsTargets) {
            dataPointDate.setMonth(dataPointDate.getMonth() + 1);
            dataPointDate.setDate(0);
          }

          return {
            value: datapoint.value,
            [datapointDateAttribute]: dataPointDate.toISOString()
          };
        });


      return datapoints;


    } else {
      return [];
    }
  };

export const abbreviateMetricValue = (value: number) => {
  const numberFormatter = Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: value >= 1000000 ? 2 : 1
  });
  return numberFormatter.format(value);
};

export const predictMetricDataPoints = (
  actualDataPoints: MetricAggregationDataPointDto[],
  metric: MetricDefinitionDto
): MetricAggregationDataPointDto[] => {
  const predictions: MetricAggregationDataPointDto[] = [];
  const dataPoints = orderBy(actualDataPoints.filter(dp => dp.value), item => new Date(item.date), 'asc');

  if (dataPoints.length >= 2) {
    let averageChange: number;
    const firstDp = dataPoints[0];
    const lastDp = dataPoints[dataPoints.length - 1];

    const numOfMonths = moment(lastDp.date).diff(moment(firstDp.date), 'months');

    if (metric.dataPointType === MetricDefinitionDtoDataPointType.independent || metric.summarize === MetricDefinitionDtoSummarize.avg || metric.summarize === MetricDefinitionDtoSummarize.sum) {
      let totalValue = 0;
      for (let i = 0; i < dataPoints.length - 1; i++) {
        totalValue += dataPoints[i + 1].value - dataPoints[i].value;
      }
      averageChange = totalValue / numOfMonths;

    } else {
      const totalChange = lastDp.value - firstDp.value;
      averageChange = totalChange / numOfMonths;
    }

    if (
      (metric.trendType === MetricDefinitionDtoTrendType.more && averageChange > 0) ||
      (metric.trendType === MetricDefinitionDtoTrendType.less && averageChange < 0)
    ) {
      const dataPointsToPredict = 12 - dataPoints.length + 1;
      if (dataPointsToPredict > 0) {
        const lastDataPoint = dataPoints[dataPoints.length - 1];
        let currentValue = lastDataPoint.value;
        const lastDataPointDate = new Date(lastDataPoint.date);
        lastDataPointDate.setHours(15);


        for (let i = 0; i < dataPointsToPredict; i++) {
          predictions.push({ date: lastDataPointDate.toISOString(), value: currentValue });
          lastDataPointDate.setDate(1);
          lastDataPointDate.setMonth(lastDataPointDate.getMonth() + 2);
          lastDataPointDate.setDate(0);

          if (currentValue + averageChange <= 0) {
            currentValue = 0;
          } else {
            currentValue += Math.round(averageChange);
          }
        }
      }
    }
  }





  // if (dataPoints.length >= 2) {
  //   let totalChange = 0;
  //   for (let i = 1; i < dataPoints.length; i++) {
  //     totalChange += dataPoints[i].value - dataPoints[i - 1].value;
  //   }
  //   const averageChange = totalChange / (dataPoints.length - 1);

  //   if (
  //     (trendType === MetricDefinitionDtoTrendType.more && averageChange > 0) ||
  //     (trendType === MetricDefinitionDtoTrendType.less && averageChange < 0)
  //   ) {
  //     const dataPointsToPredict = 12 - dataPoints.length + 1;
  //     if (dataPointsToPredict > 0) {
  //       const lastDataPoint = dataPoints[dataPoints.length - 1];
  //       let currentValue = lastDataPoint.value;
  //       const lastDataPointDate = new Date(lastDataPoint.date);
  //       lastDataPointDate.setHours(15);


  //       for (let i = 0; i < dataPointsToPredict; i++) {
  //         predictions.push({ date: lastDataPointDate.toISOString(), value: currentValue });
  //         lastDataPointDate.setDate(1);
  //         lastDataPointDate.setMonth(lastDataPointDate.getMonth() + 2);
  //         lastDataPointDate.setDate(0);

  //         if (currentValue + averageChange <= 0) {
  //           currentValue = 0;
  //         } else {
  //           currentValue += averageChange;
  //         }
  //       }
  //     }
  //   }
  // }

  return predictions;
};

export const heasderDisplayNameByPeriod = {
  [MetricDefinitionDtoPeriod.monthly]: 'Monthly',
  [MetricDefinitionDtoPeriod.quarterly]: 'Quarterly',
  [MetricDefinitionDtoPeriod.semiannually]: 'Semi Annually',
  [MetricDefinitionDtoPeriod.annually]: 'Annually'
};

export const lastPeriodDisplayNameByPeriod = {
  [MetricDefinitionDtoPeriod.monthly]: 'Month',
  [MetricDefinitionDtoPeriod.quarterly]: 'Quarter',
  [MetricDefinitionDtoPeriod.semiannually]: 'Half Year',
  [MetricDefinitionDtoPeriod.annually]: 'Year'
};
