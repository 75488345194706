import React, { useCallback, useState } from 'react';
import OutlinedFlagTwoToneIcon from '@mui/icons-material/OutlinedFlagTwoTone';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { IconButton, Stack } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import useMetricSidebarView from '../../../../hooks/context-providers/useMetricSidebarView';
import useModal from '../../../../hooks/context-providers/useModal/useModal';
import palette from '../../../../theme/palette';
import AppPopover from '../../../ActionPlan/components/AppPopover/AppPopover';
import { Container, TitleText } from './MetricViewSidebarStyles';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import MetricMetaData from './components/MetricMetaData/MetricMetaData';
import SidebarTabs from './components/SidebarTabs/SidebarTabs';
import useMetricDefinition from '../../../../hooks/useMetricDefinition';
import MetricDataPointsTable from './components/MetricDataPointsTable/MetricDataPointsTable';
import MetricTargetsTable from './components/MetricDataPointsTable/MetricTargetsTable';
import { MetricDefinitionDtoPeriod, MetricDefinitionDtoSource, MetricTargetDefinitionDto } from '../../../../api/generated';
import useMetricDataPoints from '../../../../hooks/useMetricDataPoints';
import { enqueueSnackbar } from 'notistack';
import { TOAST_VARIANTS } from '../../../../components/NotificationToast/NotificationToast';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { ReactComponent as FileExampleImage } from '../../../../assets/metric-file-example.svg';
import { orderBy } from 'lodash';
import { toUtcIsoDate } from '../../../../utils/dateUtil';
import UserView from '../../../../components/User/UserView';
import TeamsSelect from '../../../../components/Team/TeamsSelect';
const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../../../modals/AreYouSureModal/AreYouSureModal')
);


type FileRow = {
  date: string;
  value: string;
  target?: string;
};


export enum MetricTabs {
  METADATA = 'METADATA',
  DATAPOINTS = 'DATAPOINTS',
  TARGETS = 'TARGETS'
}

const LazyLoadedFileUploadModal = React.lazy(
  () => import('../../../../modals/FileDragAndDropModal/FileDragAndDropModal')
);

const LazyLoadedObjectiveModal = React.lazy(
  () => import('../../../../modals/ObjectiveModal/ObjectiveModal')
);

const LazyLoadedInitiativeModal = React.lazy(
  () => import('../../../../modals/InitiativeModal/InitiativeModal')
);


const MetricViewSidebar = () => {
  const { open, setOpen, currentMetric, setCurrentMetric, currentMetricMetadata, setCurrentMetricMetadata, refetch } = useMetricSidebarView();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { showModal } = useModal();
  const [currentTab, setCurrentTab] = React.useState(MetricTabs.METADATA);
  const [isOpen, setIsOpen] = React.useState(false);
  const { deleteMetricDefinition, refetchMetricList, patchMetricDefinition, updateMetricDefinition, resetMetricDefinitionIntegration } = useMetricDefinition();
  const { createMetricDataPoints, refetchMetricDataPoints } = useMetricDataPoints(currentMetric?.id, {
    period: currentMetric?.period !== MetricDefinitionDtoPeriod.annually ? MetricDefinitionDtoPeriod.monthly : MetricDefinitionDtoPeriod.annually,
    summarize: currentMetric?.summarize
  });

  const [ownerId, setOwnerId] = useState<string>(currentMetric.ownerId);
  const [teamIds, setTeamIds] = useState<string[]>(currentMetric?.teamIds || []);

  const updateTeamIds = (teamIds: string[]) => {
    setTeamIds(teamIds);
    if (teamIds) {
      patchMetricDefinition(currentMetric.id, { teamIds: teamIds }).then(() =>
        refetch()
      );
    } else {
      updateMetricDefinition(currentMetric.id, {
        ...currentMetric,
        teamIds: null
      }).then(() => refetch());
    }
  };


  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    if (userId) {
      patchMetricDefinition(currentMetric.id, { ownerId: userId }).then(() =>
        refetch()
      );
    } else {
      updateMetricDefinition(currentMetric.id, {
        ...currentMetric,
        ownerId: null
      }).then(() => refetch());
    }
  };

  React.useEffect(() => setIsOpen(open), [open]);

  const deleteClicked = React.useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        deleteMetricDefinition(currentMetric.id).then(
          () => {
            refetchMetricList();
            setOpen(false);
          }
        );
        setAnchorEl(null);
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete ${currentMetric.name}? `,
      confirmButtonIcon: <DeleteOutlineIcon />,
      confirmButtonTitle: 'Delete'
    });
  }, [showModal, currentMetric, deleteMetricDefinition, refetchMetricList, setOpen]);


  const createFileData = useCallback(
    (fileRows: FileRow[]) => {

      const dataPoints = fileRows
        .filter(row => row.date && row.value !== null && row.value !== '')
        .map(row => ({
          metricDefinitionId: currentMetric.id,
          createdAt: toUtcIsoDate(new Date(row.date)),
          value: parseInt(row.value)
        }));
      const fileTargets: MetricTargetDefinitionDto[] = fileRows
        .filter(row => row.date && row.target !== null && row.target !== '')
        .map(row => ({ timestamp: toUtcIsoDate(new Date(row.date)), value: parseInt(row.target) }));

      if (dataPoints?.length > 0) {
        createMetricDataPoints(dataPoints).then(() => refetchMetricDataPoints());
      }

      if (fileTargets?.length > 0) {
        patchMetricDefinition(currentMetric.id, { targets: fileTargets }).then(() => {
          refetch();
          const updatedMetric = { ...currentMetric, targets: fileTargets };
          setCurrentMetric(updatedMetric);
        });


      }
    },
    [
      createMetricDataPoints,
      refetchMetricDataPoints,
      patchMetricDefinition,
      setCurrentMetric,
      refetch,
      currentMetric
    ]
  );

  const parseCSVFile = useCallback(
    (file: Blob) => {
      try {
        Papa.parse(file, {
          complete: result => {
            createFileData(result.data as FileRow[]);
          },
          header: true
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar({
          variant: 'custom',
          customProps: {
            caption: 'Failed to upload data',
            variant: TOAST_VARIANTS.ERROR
          }
        });
      }
    },
    [createFileData]
  );

  const parseXLSXFile = useCallback(
    (file: Blob) => {
      try {
        const reader = new FileReader();
        reader.onload = e => {
          const ab = e.target.result;
          const workbook = XLSX.read(ab, { type: 'array' });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const json: FileRow[] = XLSX.utils.sheet_to_json(worksheet);
          createFileData(json);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.log(error);
        enqueueSnackbar({
          variant: 'custom',
          customProps: {
            caption: 'Failed to upload data',
            variant: TOAST_VARIANTS.ERROR
          }
        });
      }
    },
    [createFileData]
  );

  const onCreateOkr = useCallback(() => {
    let krValue = null;
    if (currentMetric.targets) {
      krValue = orderBy(currentMetric.targets, item => new Date(item.timestamp), 'desc')[0].value;
    }

    setAnchorEl(null);
    const modal = showModal(LazyLoadedObjectiveModal, {
      onCancel: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        refetchMetricList();
        modal.hide();
      },
      krValue: krValue,
      krMetric: currentMetric
    });
  }, [currentMetric, setAnchorEl, showModal, refetchMetricList]);

  const onCreateInitiative = useCallback(() => {
    let krValue = null;
    if (currentMetric.targets) {
      krValue = orderBy(currentMetric.targets, item => new Date(item.timestamp), 'desc')[0].value;
    }

    setAnchorEl(null);
    const modal = showModal(LazyLoadedInitiativeModal, {
      onCancel: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        refetchMetricList();
        modal.hide();
      },
      krValue: krValue,
      krMetric: currentMetric,
      openGoal: true
    });
  }, [currentMetric, setAnchorEl, showModal, refetchMetricList]);

  const onRemoveIntegration = React.useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: () => {
        resetMetricDefinitionIntegration(currentMetric.id).then(() => {
          refetch && refetch();
          setOpen(false);
          enqueueSnackbar({
            variant: 'custom',
            customProps: {
              caption: 'Integration successfully removed',
              variant: TOAST_VARIANTS.SUCCESS
            }
          })
        });
        modal.hide();
      },
      modalTitle: `Are you sure you want to remove ${currentMetric.automation.providerType} integration? `,
      description: "This action will remove the integration and all metric data points.",
      descriptionColor: palette.red[4],
      confirmButtonIcon: <DeleteOutlineIcon />,
      confirmButtonTitle: 'Remove'
    });
  }, [showModal, currentMetric, resetMetricDefinitionIntegration, refetch, setOpen]);

  const uploadClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedFileUploadModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: async (file: File) => {
        if (file.type === 'text/csv') {
          parseCSVFile(file);
        } else if (/\.(xlsx|xls)$/i.test(file.name)) {
          parseXLSXFile(file);
        } else {
          enqueueSnackbar({
            variant: 'custom',
            customProps: {
              caption: 'Unsupported file type',
              variant: TOAST_VARIANTS.ERROR
            }
          });
        }

        modal.hide();
      },
      modalTitle: 'Upload Metric data',
      description: 'Import from CSV/Excel file.\nFile format:date (MM/DD/YYYY),value (number),target (number)\n*Headers should be in lowercase',
      image: <FileExampleImage />,
      confirmButtonIcon: <FileUploadOutlinedIcon />,
      confirmButtonTitle: 'Upload'
    });
  }, [showModal, parseCSVFile, parseXLSXFile, setAnchorEl]);

  const [width, setWidth] = useState<number>(700);


  const actions = [
    {
      title: "Upload data",
      icon: <FileUploadOutlinedIcon fontSize='small' />,
      onAction: uploadClick
    }
  ];

  if (!currentMetric.okrMetric) {
    actions.push(
      {
        title: 'Create Objective',
        icon: <OutlinedFlagTwoToneIcon fontSize='small' />,
        onAction: onCreateOkr
      }
    );

    actions.push(
      {
        title: 'Create Initiative',
        icon: <AccountTreeOutlinedIcon fontSize='small' />,
        onAction: onCreateInitiative
      }
    );
  }

  if (currentMetric?.source === MetricDefinitionDtoSource.auto && currentMetric?.automation !== null) {
    actions.push(
      {
        title: 'Remove Integration',
        icon: <HighlightOffOutlinedIcon fontSize='small' />,
        onAction: onRemoveIntegration
      }
    );
  }


  return (
    <Container
      sx={{
        "& .MuiDrawer-paper": {
          width: width
        }
      }}
      anchor="right"
      open={isOpen}
      onClose={() => {
        setCurrentMetric(null);
        setCurrentMetricMetadata(null);
        setOpen(false);
      }}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <Stack alignItems="center" justifyContent="space-between" direction="row" padding="20px 20px 5px 20px">
        <Stack alignItems="center" gap="7px" direction="row">
          <QueryStatsIcon sx={{ color: palette.green[1] }} />
          <TitleText sx={{ marginBottom: '5px' }}>{currentMetric.name}</TitleText>
        </Stack>
        <Stack alignItems="center" gap="5px" direction="row">
          <IconButton
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
            sx={{ p: '4px' }}
          >
            <MoreHorizIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setCurrentMetric(null);
              setCurrentMetricMetadata(null);
              setOpen(false);
            }}
            sx={{ p: '4px' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <AppPopover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClose={() => setAnchorEl(null)}
          onDelete={deleteClicked}
          customActions={actions}
        />
      </Stack>

      <Stack
        paddingLeft="20px"
        paddingRight="20px"
        width="100%"
        paddingTop="3px"
        direction="row"
        alignItems="center"
        gap={1}>



        <UserView
          tooltip='Owner'
          onlyAvatar
          size='30px'
          userId={ownerId}
          onChange={updateOwnerId}
        />

        <TeamsSelect
          size='30px'
          tooltip
          teamIds={teamIds}
          onChange={updateTeamIds} />

      </Stack>
      <SidebarTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <Stack sx={{ maxWidth: '100%' }}>
        {currentTab === MetricTabs.METADATA &&
          <MetricMetaData
            currentMetric={currentMetric}
            currentMetricMetadata={currentMetricMetadata}
            setWidth={setWidth}
            width={width} />}
        {currentTab === MetricTabs.DATAPOINTS && (
          <MetricDataPointsTable currentMetric={currentMetric} refetch={refetch} />
        )}
        {currentTab === MetricTabs.TARGETS && (
          <MetricTargetsTable currentMetric={currentMetric} refetch={refetch} />
        )}
      </Stack>
    </Container>
  );
};

export default MetricViewSidebar;
