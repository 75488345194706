import React from 'react';

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Stack, Typography, styled } from '@mui/material';

import {
  MetricAggregationDataPointDto,
  MetricDefinitionDto,
  MetricDefinitionDtoTrendType,
  MetricDefinitionDtoUnit
} from '../../../../api/generated';
import metricUnitTypesTitles from '../../../../mappings/MetricUnitTypesTitle';
import palette from '../../../../theme/palette';
import { abbreviateMetricValue } from '../../../../utils/metricUtil';

const ValueTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '2rem'
});

const TrendTitle = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.7rem'
});


const ValueWithTrend = ({
  metric,
  metricDataPoints = [],
  width
}: {
  metric: MetricDefinitionDto;
  metricDataPoints: MetricAggregationDataPointDto[];
  width?: string;
}) => {
  const lastValueIndex = React.useMemo(
    () => metricDataPoints.findLastIndex(datapoint => datapoint.value !== null),
    [metricDataPoints]
  );
  const lastValue = React.useMemo(
    () => metricDataPoints[lastValueIndex]?.value,
    [lastValueIndex, metricDataPoints]
  );


  const lastTwoValuesDeduction = React.useMemo(() => {
    if (lastValue && metricDataPoints[lastValueIndex - 1]?.value !== null) {
      return lastValue - metricDataPoints[lastValueIndex - 1]?.value;
    } else {
      return null;
    }
  }, [lastValue, lastValueIndex, metricDataPoints]);

  const trendColor = React.useMemo(() => {
    if (
      (metric.trendType === MetricDefinitionDtoTrendType.more && lastTwoValuesDeduction > 0) ||
      (metric.trendType === MetricDefinitionDtoTrendType.less && lastTwoValuesDeduction < 0)
    ) {
      return palette.green[1];
    } else {
      return palette.red[4];
    }
  }, [lastTwoValuesDeduction, metric.trendType]);


  return (
    <>
      {lastValue !== undefined && (
        <Stack justifyContent={!lastTwoValuesDeduction && 'center'} gap="10px" width={width ? width : "200px"}>
          <ValueTitle>
            {metric.unit === MetricDefinitionDtoUnit.percentage
              ? abbreviateMetricValue(lastValue) + metricUnitTypesTitles[metric.unit]
              : metricUnitTypesTitles[metric.unit] + abbreviateMetricValue(lastValue)}
          </ValueTitle>
          {!Number.isNaN(lastTwoValuesDeduction) &&
            lastTwoValuesDeduction != null &&
            lastTwoValuesDeduction !== 0 && (
              <Stack direction="row" alignItems="center" gap="5px">
                {lastTwoValuesDeduction > 0 && <TrendingUpIcon sx={{ color: trendColor }} />}
                {lastTwoValuesDeduction < 0 && <TrendingDownIcon sx={{ color: trendColor }} />}
                {
                  <Stack direction="row" gap="2px">
                    <TrendTitle color={trendColor}>
                      {metric.unit === MetricDefinitionDtoUnit.percentage
                        ? abbreviateMetricValue(lastTwoValuesDeduction) +
                        metricUnitTypesTitles[metric.unit]
                        : metricUnitTypesTitles[metric.unit] +
                        abbreviateMetricValue(lastTwoValuesDeduction)}
                    </TrendTitle>

                  </Stack>
                }
              </Stack>
            )}
        </Stack>
      )}
    </>
  );
};

export default React.memo(ValueWithTrend);
