import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import OutlinedFlagTwoToneIcon from '@mui/icons-material/OutlinedFlagTwoTone';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { CircularProgress, Stack, alpha } from '@mui/material';

import {
  ActionPlanMetricMetadataDto,
  ActionPlanMetricMetadataDtoType,
  MetricAggregationDataPointDto,
  MetricDefinitionDto,
  MetricDefinitionDtoDataPointType,
  MetricDefinitionDtoPeriod,
  MetricDefinitionDtoSummarize,
  MetricDefinitionDtoTrendType,
  MetricTargetDefinitionDto
} from '../../../../../../api/generated';
import useAccountFiscalMonth from '../../../../../../hooks/useAccountFiscalMonth';
import useMetricDataPoints from '../../../../../../hooks/useMetricDataPoints';

import palette from '../../../../../../theme/palette';
import { getDatePeriod, getPeriodDate, isSameMonth } from '../../../../../../utils/dateUtil';
import {
  abbreviateMetricValue,
  buildDataPointsForChart,
  lastPeriodDisplayNameByPeriod,
  predictMetricDataPoints
} from '../../../../../../utils/metricUtil';
import ValueWithTrend from '../../../ValueWithTrend/ValueWithTrend';
import MetricChart from '../MetricChart/MetricChart';
import { ActionPlanContainer, ActionPlanEntityDetails, ActionPlanEntityGoal, ActionPlanEntityTitle, ActionPlanTitle, ChartHeader, TargetPercentageView, TargetTitle } from './MetricMetaDataStyles';
import UserView from '../../../../../../components/User/UserView';


const MetricMetaData = ({
  currentMetric,
  setWidth,
  currentMetricMetadata,
  width
}: {
  currentMetric: MetricDefinitionDto;
  currentMetricMetadata: ActionPlanMetricMetadataDto;
  width: number;
  setWidth: (value: number) => void;
}) => {
  const { metricDatapoints, metricDataPointsLoading } = useMetricDataPoints(currentMetric?.id, {
    period: currentMetric.period !== MetricDefinitionDtoPeriod.annually ? MetricDefinitionDtoPeriod.monthly : MetricDefinitionDtoPeriod.annually,
    summarize: currentMetric.summarize
  });



  const { monthToStart } = useAccountFiscalMonth();

  const yearlyDatapoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    metricDatapoints,
    true
  );



  const allDatapoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    metricDatapoints,
    false,
    false
  );

  const lastYearDatapoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    metricDatapoints,
    true,
    true
  );

  const currentDataPoint = metricDatapoints?.[metricDatapoints?.length - 1];
  const currentDataPointPeriod = currentDataPoint && getDatePeriod(currentMetric.period, monthToStart, new Date(currentDataPoint.date));



  const targetDatapoint = useMemo(
    () => {
      if (currentDataPoint && currentDataPointPeriod) {
        const periodDate = getPeriodDate(currentMetric.period, monthToStart, currentDataPointPeriod);
        return currentMetric.targets?.find(target =>
          isSameMonth(new Date(target.timestamp), periodDate)
        );
      }
    },
    [currentMetric, currentDataPoint, currentDataPointPeriod, monthToStart]
  );


  const calcProgress = useCallback(() => {
    const retVal = { value: 0, percentage: 0 };
    console.log(currentMetric);

    if (targetDatapoint) {
     
      

      if (currentMetric.dataPointType === MetricDefinitionDtoDataPointType.independent || currentMetric.summarize === MetricDefinitionDtoSummarize.avg || currentMetric.summarize === MetricDefinitionDtoSummarize.sum) {
        const periodDataPoints = metricDatapoints?.filter(dp => {
          const period = getDatePeriod(currentMetric.period, monthToStart, new Date(dp.date));
          return period === currentDataPointPeriod;
        });

        retVal.value = periodDataPoints?.reduce((acc, dp) => acc + dp.value, 0) || 0;

      } else {
        if (currentDataPoint) {
          retVal.value = currentDataPoint.value
        }

      }

      if (currentMetric.trendType === MetricDefinitionDtoTrendType.more) {
        retVal.percentage = Math.trunc(retVal.value / targetDatapoint.value * 100);
      } else {
        retVal.percentage = Math.trunc(targetDatapoint.value / retVal.value * 100);
      }
    }



    return retVal;
  }, [
    currentMetric,
    currentDataPoint,
    targetDatapoint,
    currentDataPointPeriod,
    metricDatapoints,
    monthToStart
  ]);


  const [progress, setProgress] = useState(null);



  const [targetDatapoints, setTargetDatapoints] = useState<MetricTargetDefinitionDto[]>(
    buildDataPointsForChart<MetricTargetDefinitionDto>(
      monthToStart,
      currentMetric?.targets?.sort(
        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
      currentMetric.period !== MetricDefinitionDtoPeriod.annually,
      false
    ));

  useEffect(() => {
    setTargetDatapoints(buildDataPointsForChart<MetricTargetDefinitionDto>(
      monthToStart,
      currentMetric?.targets?.sort(
        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
      currentMetric.period !== MetricDefinitionDtoPeriod.annually,
      false
    ));

    setProgress(calcProgress());
  }, [currentMetric, monthToStart, setTargetDatapoints, calcProgress, setProgress]);



  const predictionDataPoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    predictMetricDataPoints(yearlyDatapoints, currentMetric),
    currentMetric.period !== MetricDefinitionDtoPeriod.annually,
    false
  );

















  return (
    <Stack padding="20px" gap="5px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {metricDataPointsLoading ? (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          yearlyDatapoints.length > 0 && (
            <ValueWithTrend metric={currentMetric} metricDataPoints={yearlyDatapoints} />
          )
        )}

        {targetDatapoint && progress?.percentage >= 100 && (
          <TargetTitle sx={{ mt: 1 }} customColor={palette.common.darkBlue}>
            👏 Great work!
          </TargetTitle>
        )}


        {targetDatapoint?.value && (
          <Stack alignItems="center">
            <TargetTitle>
              Current {lastPeriodDisplayNameByPeriod[currentMetric.period]} Progress
            </TargetTitle>
            <ChartHeader marginTop="5px" alignItems="center">
              <Stack direction="row" alignItems="center" gap={1}>
                <TargetPercentageView>{
                  abbreviateMetricValue(progress?.value) + " / " +
                  abbreviateMetricValue(targetDatapoint.value)
                }</TargetPercentageView>
                <Stack width="60px" height="60px">
                  <CircularProgressbarWithChildren
                    value={progress?.percentage || 0}
                    text={
                      `${progress?.percentage || 0}%`
                    }
                    styles={buildStyles({
                      textSize: '22px',
                      pathColor: palette.primary.main,
                      textColor: palette.primary.main
                    })}
                  ></CircularProgressbarWithChildren>
                </Stack>

              </Stack>
            </ChartHeader>
            <ChartHeader alignItems="center" width="100%"></ChartHeader>
          </Stack>
        )
        }
      </Stack>
      {metricDataPointsLoading ? (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <MetricChart
          currentMetric={currentMetric}
          metricDatapoints={yearlyDatapoints}
          allDatapoints={allDatapoints}
          lastYearDatapoints={lastYearDatapoints}
          targetDatapoints={targetDatapoints}
          predictionDataPoints={predictionDataPoints}
          width={width}
          setWidth={setWidth}
        />
      )}


      {currentMetricMetadata &&
        <ActionPlanContainer>
          <Stack
            sx={{
              svg: { path: { fill: alpha(palette.blue[9], 0.4) } }
            }}
            direction="row"
            alignItems="center"
            gap={1}>
            {currentMetricMetadata.type === ActionPlanMetricMetadataDtoType.Objective ? <OutlinedFlagTwoToneIcon sx={{ width: "30px", height: "30px" }} /> : <AccountTreeOutlinedIcon sx={{ width: "30px", height: "30px" }} />}
            <ActionPlanTitle>Connected {currentMetricMetadata.type === ActionPlanMetricMetadataDtoType.Objective ? "OKR" : "Initiative"}</ActionPlanTitle>
          </Stack>

          <ActionPlanEntityDetails
          >
            <ActionPlanEntityTitle> {currentMetricMetadata.title}</ActionPlanEntityTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">

              <ActionPlanEntityGoal>{currentMetricMetadata.goalDescription}</ActionPlanEntityGoal>
              {currentMetricMetadata.ownerId &&
                <UserView
                  onlyAvatar
                  size='26px'
                  viewOnly
                  userId={currentMetricMetadata.ownerId} />}
            </Stack>
          </ActionPlanEntityDetails>
        </ActionPlanContainer>}
    </Stack>
  );
};

export default MetricMetaData;
