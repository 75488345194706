import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  styled
} from '@mui/material';
import { ApexOptions } from 'apexcharts';

import {
  MetricAggregationDataPointDto,
  MetricDefinitionDto,

  MetricDefinitionDtoPeriod,
  MetricDefinitionDtoSummarize
} from '../../../../../../api/generated';
import CustomSwitch from '../../../../../../components/Common/CustomSwitch';
import useAccountFiscalMonth from '../../../../../../hooks/useAccountFiscalMonth';
import metricUnitTypesTitles from '../../../../../../mappings/MetricUnitTypesTitle';
import palette from '../../../../../../theme/palette';

import { abbreviateMetricValue } from '../../../../../../utils/metricUtil';

function customTooltip({ series, seriesIndex, dataPointIndex, w }) {

  const hoveredXValue = w.globals.seriesX[seriesIndex][dataPointIndex];

  let hoverList = '';
  series.forEach((s, seriesEachIndex) => {

    if (w.globals.collapsedSeriesIndices.includes(seriesEachIndex) === false) {
      const shouldHandle = w.globals.seriesX[seriesEachIndex].some(dp => dp === hoveredXValue);
      if (shouldHandle) {
        const index = w.globals.seriesX[seriesEachIndex].findLastIndex(dp => dp === hoveredXValue);

        if (series[seriesEachIndex][index] !== undefined) {


          hoverList += `
  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
      <span class="apexcharts-tooltip-marker" style="background-color: ${w.globals.markers.colors[seriesEachIndex]
            };"></span>
      <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
          <div class="apexcharts-tooltip-y-group">
              <span class="apexcharts-tooltip-text-y-label">${w.globals.seriesNames[seriesEachIndex]
            }: </span>
              <span class="apexcharts-tooltip-text-y-value">${w.globals.yLabelFormatters[0](
              series[seriesEachIndex][index]
            )}</span>
          </div>
      </div>
  </div>`;
        }
      }

    }
  });

  return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${new Date(hoveredXValue).toLocaleString([], {
    day: "2-digit",
    month: 'short',
    timeZone: 'UTC'
  })
    }</div>${hoverList}`;
}

const YTDToggleTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '14px'
});

enum ChartViewType {
  YEARLY = 'YEARLY',
  ALL = 'ALL'
}

const ChartViewTypeTitles = {
  [ChartViewType.YEARLY]: 'Year-over-Year',
  [ChartViewType.ALL]: 'All historical data'
};

type ChartData = {
  options: ApexOptions;
  series: ApexOptions['series'];
};

const MetricChart = ({
  currentMetric,
  metricDatapoints,
  allDatapoints,
  lastYearDatapoints,
  targetDatapoints,
  predictionDataPoints,
  currentDateAnnotation = true,
  small,
  width,
  height,
  setWidth
}: {
  currentMetric: MetricDefinitionDto;
  metricDatapoints: MetricAggregationDataPointDto[];
  allDatapoints: MetricAggregationDataPointDto[];
  lastYearDatapoints: MetricAggregationDataPointDto[];
  targetDatapoints: MetricDefinitionDto['targets'];
  predictionDataPoints?: MetricAggregationDataPointDto[];
  currentDateAnnotation?: boolean;
  small?: boolean;
  width: number;
  height?: number;
  setWidth?: (value: number) => void;
}) => {
  const [chartViewType, setChartViewType] = React.useState(
    currentMetric.period === MetricDefinitionDtoPeriod.annually
      ? ChartViewType.ALL
      : ChartViewType.YEARLY
  );

  const { monthToStart } = useAccountFiscalMonth();


  const [cumulative, setCumulative] = useState(false);

  const getTooltipXIndex = React.useCallback(() => {
    if (metricDatapoints.length > 0) {
      const lastDate = new Date(metricDatapoints[metricDatapoints.length - 1].date);

      return lastDate.getTime();
    } else {
      return 0;
    }


  }, [metricDatapoints]);



  const fillTypes = React.useMemo(() => [], []);



  const annotations = React.useMemo(
    () => ({
      xaxis:
        chartViewType === ChartViewType.YEARLY
          ? [
            {
              x: getTooltipXIndex(),
              strokeDashArray: 5,
              label: {
                borderColor: alpha(palette.primary.main, 0.2),
                style: {
                  color: palette.grey[3100]
                },
                text: 'Last updated',
                offsetY: -15,
                orientation: 'horizontal',
                textAnchor: 'middle'
              }
            }
          ]
          : []
    }),
    [getTooltipXIndex, chartViewType]
  );

  const minXaxisDate = useMemo(() => {
    const tempDate = new Date();
    if (monthToStart !== 1 && monthToStart > tempDate.getMonth() + 1) {
      tempDate.setFullYear(tempDate.getFullYear() - 1);
    }
    tempDate.setMonth(monthToStart - 1);
    tempDate.setDate(1);
    return tempDate;
  }, [monthToStart]);



  const maxXaxisDate = useMemo(() => {
    const tempDate = new Date(minXaxisDate);
    tempDate.setMonth(tempDate.getMonth() + 12);
    tempDate.setDate(0);
    return tempDate;
  }, [minXaxisDate]);





  const currentSeries = React.useMemo(() => {
    const tempSeries = [];

    if (chartViewType === ChartViewType.ALL) {
      if (allDatapoints.some(datapoint => datapoint.value !== null)) {
        tempSeries.push({
          name: 'All',
          color: palette.green[1],
          data: allDatapoints.map(dataPoint => ({ x: dataPoint.date, y: dataPoint.value }))
        });
        fillTypes.push('solid');
      }
      if (currentMetric.period === MetricDefinitionDtoPeriod.annually) {
        if (targetDatapoints.some(datapoint => datapoint)) {
          tempSeries.push({
            name: 'Target',
            color: (alpha(palette.purple[1], 0.6)),
            data: cumulative
              ? targetDatapoints.reduce(
                (a, current, i) => [
                  ...a,
                  {
                    x: current.timestamp,
                    y: current.value
                      ? current.value + (a[i - 1].y || 0)
                      : i > 0 && (a[i - 1].y || 0)
                  }
                ],
                []
              )
              : targetDatapoints.map(dataPoint => ({ x: dataPoint.timestamp, y: dataPoint.value }))
          });
          fillTypes.push('solid');
        }

        if (predictionDataPoints?.length > 0) {
          tempSeries.push({
            name: 'Prediction',
            color: alpha(palette.green[1], 0.5),
            data: cumulative
              ? predictionDataPoints.reduce(
                (a, x, i) => [
                  ...a,
                  x.value ? { ...x, value: x.value + (a[i - 1] || 0) } : i > 0 && (a[i - 1] || 0)
                ],
                []
              )
              : predictionDataPoints.map(dataPoint => ({
                date: dataPoint.date,
                value: dataPoint.value
              }))
          });
          fillTypes.push('pattern');
        }
      }
    } else {


      if (lastYearDatapoints.some(datapoint => datapoint.value !== null !== null)) {
        tempSeries.push({
          name: 'Last Year',
          color: alpha(palette.blue[4], 0.6),
          data: cumulative
            ? lastYearDatapoints.reduce(
              (a, current, i) => [
                ...a,
                {
                  x: current.date,
                  y: current.value
                    ? current.value + (a[i - 1]?.y || 0)
                    : i > 0 && (a[i - 1]?.y || 0)
                }
              ],
              []
            )
            : lastYearDatapoints.map(dataPoint => ({ x: dataPoint.date, y: dataPoint.value }))
        });
        fillTypes.push('solid');
      }

      if (targetDatapoints.some(datapoint => datapoint.value !== null !== null)) {
        tempSeries.push({
          name: 'Target',
          color: alpha(palette.purple[1], 0.6),
          data: cumulative
            ? targetDatapoints.reduce(
              (a, current, i) => [
                ...a,
                {
                  x: current.timestamp,
                  y: current.value
                    ? current.value + (a[i - 1]?.y || 0)
                    : i > 0 && (a[i - 1]?.y || 0)
                }
              ],
              []
            )
            : targetDatapoints.map(dataPoint => ({ x: dataPoint.timestamp, y: dataPoint.value }))
        });
        fillTypes.push('solid');
      }


      if (metricDatapoints.some(datapoint => datapoint.value !== null !== null)) {
        tempSeries.push({
          name: 'Actual',
          color: palette.green[1],
          data: cumulative
            ? metricDatapoints.reduce(
              (a, current, i) => [
                ...a,
                {
                  x: current.date,
                  y: current.value
                    ? current.value + (a[i - 1]?.y || 0)
                    : i > 0 && (a[i - 1]?.y || 0)
                }
              ],
              []
            )
            : [
              ...metricDatapoints.map(dataPoint => ({
                x: dataPoint.date,
                y: dataPoint.value
              }))
            ]
        });
        fillTypes.push('solid');
      }

      if (predictionDataPoints?.length > 0) {
        const cumulativeList = metricDatapoints
          .reduce(
            (a, current, i) => [
              ...a,
              {
                x: current.date,
                y: current.value ? current.value + (a[i - 1]?.y || 0) : null
              }
            ],
            []
          )
          .filter(item => item?.y !== null);



        const lastCumulativeValue = cumulativeList[cumulativeList?.length - 2].y;

        const cumulativePrediction = [];
        let lastValue = lastCumulativeValue;

        predictionDataPoints.forEach(dp => {
          let newValue = null;
          if (dp.value !== null) {
            newValue = lastValue + dp.value;
            lastValue = newValue;
          }
          cumulativePrediction.push({ x: dp.date, y: newValue });
        });


        tempSeries.push({
          name: 'Prediction',
          color: alpha(palette.green[1], 0.5),
          data: cumulative
            ? cumulativePrediction
            : predictionDataPoints.map(dataPoint => ({ x: dataPoint.date, y: dataPoint.value }))
        });
        fillTypes.push('pattern');
      }

    }

    return tempSeries;
  }, [
    chartViewType,
    allDatapoints,
    currentMetric.period,
    fillTypes,
    targetDatapoints,
    predictionDataPoints,
    cumulative,
    metricDatapoints,
    lastYearDatapoints
  ]);

  const chartData: ChartData = React.useMemo(
    () => ({
      options: {
        grid: {
          padding: {
            right: 10,
            left: 20,
            top: 10,
            bottom: 20
          }
        },
        legend: {
          fontFamily: 'Plus Jakarta Sans'
        },
        markers: {
          size: 4
        },
        stroke: {
          width: 5,
          curve: 'monotoneCubic'
        },
        chart: {
          id: currentMetric.id

        },
        ...(currentMetric.period !== MetricDefinitionDtoPeriod.annually &&
          currentDateAnnotation &&
          metricDatapoints?.filter(dp => dp.value !== null)?.length > 0 && {
          annotations: annotations
        }),
        fill: {
          type: fillTypes,
          pattern: {
            style: 'verticalLines',
            strokeWidth: 5
          }
        },
        xaxis: {
          type: 'datetime',
          min: chartViewType === ChartViewType.YEARLY && minXaxisDate.getTime(),
          max: chartViewType === ChartViewType.YEARLY && maxXaxisDate.getTime(),
          labels: {
            offsetX: chartViewType === ChartViewType.YEARLY ? 24 : 0,
            ...(chartViewType === ChartViewType.YEARLY ?
              {
                datetimeFormatter: {
                  year: "yyyy",
                  month: "MMM",
                  day: 'dd MMM'
                }
              } : {
                formatter(value, timestamp) {
                  const datetime = new Date(timestamp);
                  return datetime.toLocaleString([], {
                    month: 'short',
                    year: "2-digit"
                  })
                }
              })



          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return metricUnitTypesTitles[currentMetric.unit] + abbreviateMetricValue(value);
            }
          }
        },
        tooltip: {
          custom: customTooltip
        }
      },
      series: currentSeries
    }),
    [
      currentMetric.id,
      currentMetric.period,
      currentMetric.unit,
      currentDateAnnotation,
      metricDatapoints,
      annotations,
      fillTypes,
      chartViewType,
      minXaxisDate,
      maxXaxisDate,
      currentSeries
    ]
  );


  useEffect(() => {
    if (chartViewType === ChartViewType.ALL) {
      setCumulative(false);
      setWidth && setWidth(1000);
    } else {
      setWidth && setWidth(700);
    }
  }, [chartViewType, setWidth]);



  

  return (
    <Stack justifyContent="center" alignItems="center">

      <Stack width="100%">
        <ReactApexChart
          height={height ? height : "400px"}
          width={width - 30}
          key={currentMetric.id}
          options={chartData.options}
          series={chartData.series}
          type="line"
        />
      </Stack>
      <Stack
        width="100%"
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="center">
        {currentMetric.period !== MetricDefinitionDtoPeriod.annually && !small && (
          <>

            <ToggleButtonGroup
              color="primary"
              value={chartViewType}
              exclusive
              onChange={(_, selectedChartViewType) =>
                selectedChartViewType && setChartViewType(selectedChartViewType)
              }
              aria-label="Platform"
            >
              {Object.values(ChartViewType).map(chartViewType => (
                <ToggleButton
                  style={{ textTransform: 'none', padding: "0px 10px", height: "30px" }}
                  key={chartViewType}
                  value={chartViewType}
                >
                  {ChartViewTypeTitles[chartViewType]}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            {(!currentMetric.summarize || currentMetric.summarize === MetricDefinitionDtoSummarize.lastValue) && 
            <Stack direction="row" alignItems="center" gap="5px">
              <YTDToggleTitle>YTD</YTDToggleTitle>
              <CustomSwitch
                disabled={chartViewType !== ChartViewType.YEARLY}
                checked={cumulative}
                onChange={() => setCumulative(prev => !prev)}
              />
            </Stack>}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default MetricChart;
